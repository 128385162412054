import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from "@angular/core";
import {
  IUploadedFileInfo,
  UploadInputComponent
} from "../../../oex-ui-kit/components/upload-input/upload-input.component";
import {FormsModule} from "@angular/forms";
import {TooltipDirective} from "../../../oex-ui-kit/components/tooltip/tooltip.directive";
import {NgSelectModule} from "@ng-select/ng-select";
import {IUserProfileData} from "../../services/api.types";
import {APIService} from "../../services/api.service";
import {AuthService} from "../../services/auth.service";
import {ProgressService} from "../../../oex-ui-kit/services/progress.service";
import {ModalService} from "../../services/modal.service";
import {ActivatedRoute, ActivatedRouteSnapshot, ResolveFn} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

export const resolveUserProfileData: ResolveFn<IUserProfileData> = (route: ActivatedRouteSnapshot) => {
  const auth = inject(AuthService);
  return inject(APIService).getUserProfile(auth.user.id);
}


@Component({
  selector: 'st-profile-editor-page',
  imports: [
    UploadInputComponent,
    FormsModule,
    TooltipDirective,
    NgSelectModule
  ],
  templateUrl: './profile-editor-page.component.html',
  styleUrl: './profile-editor-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileEditorPageComponent implements OnInit {
  private api = inject(APIService);
  auth = inject(AuthService);
  private cd = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);
  private ps = inject(ProgressService);
  private modal = inject(ModalService);

  model: IUserProfileData = {} as IUserProfileData;
  data$ = this.route.data.pipe(takeUntilDestroyed());

  get avatar() {
    if (!this.model?.avatar?.id) {
      return '';
    }
    return this.api.getUserAvatarUrl(this.model.avatar);
  }

  ngOnInit() {
    this.data$.subscribe(data => {
      this.model = data['profile'] as IUserProfileData;
    });
  }

  uploadFile(control: UploadInputComponent, f: File, field: string) {
    if (!f) {
      return;
    }
    void this.uploadFromControl(control, this.api.getUserAvaUploadUrl(this.auth.user.id));
  }

  /**
   * Save account data
   */
  async save() {
    this.ps.show();
    try {
      await this.api.saveUserProfile(this.auth.user.id, this.model)
      this.modal.showError('Profile saved successfully.');
    } catch (e) {
      this.modal.showError(e);
    }
    this.ps.hide();
    this.cd.detectChanges();
  }

  onSaveClick() {
    void this.save();
  }

  /**
   * Returns user photo url
   * @returns {string} Url
   */
  /*  get userPhoto() {
      if (!this.auth.user?.avatar) {
        return '';
      }
      return this.api.getFileDownloadUrl(this.data?.logo);
    }*/

  /**
   * Changes account password
   */
  /*async changePassword() {
    if (!this.modelPwd.oldPassword || !this.modelPwd.password || !this.modelPwd.password2) {
      void this.modal.show('Please fill all required fields');
      return;
    }
    if (this.modelPwd.password !== this.modelPwd.password2) {
      void this.modal.show('Entered passwords is not equal');
      return;
    }

    this.ps.show();
    try {
      //await this.api.changeUserPassword(this.modelPwd)
      this.modelPwd = {
        oldPassword: '',
        password: '',
        password2: ''
      };
      this.modal.showError('Password changed successfully.');
    } catch (e) {
      this.modal.showError(e);
    }
    this.ps.hide();
    this.cd.detectChanges();
  }*/

  /**
   * Returns part of firstName
   * @param idx
   */

  /*getNamePart(idx: number): string {
    if (!this.model.firstName) {
      return '';
    }
    const parts = this.model.firstName.trim().split(' ');
    return parts[idx] || '';
  }*/

  async removeAvatar() {
    this.ps.show();
    try {
      await this.api.deleteUserFile(this.model.avatar);
      this.model.avatar = {} as IUploadedFileInfo;
    } catch (e) {
      this.modal.showError(e);
    }
    this.ps.hide();
    this.cd.detectChanges();
  }

  private async uploadFromControl(control: UploadInputComponent, endpoint = '') {
    control.endpoint.set(endpoint);
    this.ps.show();
    return control.startUpload()
      .finally(() => {
        this.ps.hide();
        control.cd.detectChanges();
      });
  }
}
